const english = {
  /* ------------------------------ Nav Bar ----------------------------- */
  navBar: {
    options: ["SOLUTIONS", "WE ARE", "BLOG", "OUR PLATFORM"],
    contact: "CONTACT",
  },
  /* ------------------------------ Footer ------------------------------ */
  footer: {
    contact: "CONTACT",
    text: "All rights reserved.",
    privacyPolicy: "Privacy Policy",
    socialMediaMessages: {
      whatsApp:
        "Hi%21%20I%27d%20like%20to%20get%20more%20information%20about%20LAMBDA.",
    },
  },
  /* ----------------------------- Not Found ---------------------------- */
  notFound: {
    title: "Page not found",
    content: [
      {
        beforeLambda: `Sorry, we couldn't find the page you requested. Try searching
        something else or go to the home page of`,
        afterLambda: `.`,
      },
      "Keep visiting our page to learn more about what we do.",
    ],
  },
  /* -------------------------- Business Cards -------------------------- */
  businessCards: {
    donhe: {
      name: "Donhe Fuentes M. Sc.",
      job: "Data Scientist",
      description: `Data Scientist with more than 5 years of experience creating
      solutions for companies in different industries. An engineer in
      mechatronics with a master's degree in Operations Research.`,
    },
    preng: {
      name: "Preng Biba M. Sc.",
      job: "VP Analytics",
      description: `Full Stack Data Scientist with more than 10 years of
      experience. Has a master's degree in Operations Research and
      another in Embedded Systems, as well as an engineer in
      Mechatronics at Galileo University.`,
    },
    jorge: {
      name: "Dr. Jorge Samayoa",
      job: "CEO",
      description: `Data Scientist with more than 20 years of experience
      professional. Has a Ph.D. from the University of Purdue and
      a master's degree in Mathematics at Texas A&M, as well as
      another in Operations Research at Galileo University.
      An engineer in Electronics and has been working as a
      scientist and consultant for his professional life.`,
    },
    andres: {
      name: "Andrés Cazali M. Sc.",
      job: "Data Scientist",
      description: `Data Scientist with more than 5 years of experience in
      different industries. An engineer in electronics and
      has a master's degree in Biomedical Engineering at
      Galileo University. He has worked in the field of
      scientific research for Galileo University in
      conjunction with IMTEK of Freiburg University.`,
    },
    luisfer: {
      name: "Eng. Luis Fernando Guzmán",
      job: "Key Account Manager",
      description: `Expert in key account management, customer service and
      sales processes. Business Engineer from Francisco Marroquín University
      with a Master's degree in Operations Management from Galileo University.
      Extensive experience in sales and university teaching.`,
    },
  },
  /* ------------------------------ Contact ----------------------------- */
  contact: {
    title: "Contact us",
    description: `We want to hear from you and help you find the best solution
    of data science to your needs.`,
    content: ["Leave us your data and we'll get back to you shortly."],
    button: "Send",
    inputs: {
      names: "Names",
      lastNames: "Last names",
      email: "Email",
      area: "Working area",
      company: "Company",
      moreInfo: "I want more information about...",
      message: "Your message",
      phone: "Phone",
    },
    areaOptions: [
      "Working area",
      "Administration",
      "Agribusiness",
      "Commerce",
      "Communication",
      "Consulting",
      "Education",
      "Financial",
      "Legal",
      "Healthcare",
      "Information and communication technologies",
      "Telecommunications",
      "Transportation",
      "Tourism",
      "Other",
    ],
    moreInfoOptions: [
      "I want more information about...",
      "LAMBDA",
      "Solutions",
      "Consulting",
      "How can I work with you",
      "Production planning",
      "Quit prediction",
      "LAMBDA SAP",
      "Potential customers",
      "Customized solutions",
      "Other",
    ],
    defaultMessages: {
      advice: "I would like an advice on how I can use my data.",
      productionPlanning:
        "I would like to request the production planning model.",
      resignationPrediction: [
        "I would like to receive advice on how the resignation prediction works.",
        "I would like to retain the employees of my company.",
      ],
      lambdaSAP: [
        "I would like to know more about the SAP LAMBDA solution.",
        "I would like to test the LAMBDA SAP solution in my company.",
      ],
      potentialCustomers: [
        "I would like to request the potential customer identification model.",
        "I would like to test the potential customer identification model in my company.",
      ],
      customizedSolutions: [
        "I would like to receive advice on how the personalized solutions model works.",
        "I would like to use the customized solutions model in my company.",
        "I want to take advantage of my company's data with this model.",
      ],
    },
  },
  /* --------------------------- Message Sent --------------------------- */
  messageSent: {
    success: `Your data has been successfully sent to our team of professionals.
        Thank you for trusting LAMBDA, we will be contacting you as soon as possible
        possible.`,
    error: `Your data could not be sent correctly. Check your connection to
        internet and try again.`,
  },
  /* -------------------------- Privacy Policy -------------------------- */
  privacyPolicy: {
    title: "Privacy Policy",
    description: `This Privacy Policy establishes the terms in which LAMBDA uses and
      protects the information that is provided by its users when using its
      website. This company is committed to the security of its users' data.
      When we ask you to fill out personal information fields with which you can be identified,
      we do so ensuring that it will only be used in accordance with the terms of this document. However, this
      privacy policy may change over time or be updated, so we recommend and emphasize
      please review this page continually to ensure that you agree to any such changes.`,
    sections: [
      {
        title: "Information that is collected",
        description: [
          `Our website may collect personal information, for example: name, contact information
          such as your email address and demographic information. Likewise, when necessary, it may be required
          specific information to process an order or make a delivery or billing.`,
        ],
      },
      {
        title: "Use of collected information",
        description: [
          `Our website uses the information in order to provide the
          best possible service, particularly to keep a record of
          users, orders if applicable, and improve our products
          and services. Emails may be sent
          periodically through our site with special offers,
          new products and other advertising information that we consider
          relevant to you or that may provide you with some benefit, these
          Emails will be sent to the address you
          provide and may be canceled at any time.`,
          `LAMBDA is highly committed to fulfilling the commitment of
          keep your information secure. We use the most advanced systems and
          We constantly update them to ensure that there is no
          unauthorized access.`,
        ],
      },
      {
        title: "Cookies",
        description: [
          `A cookie refers to a file that is sent for the purpose of
          request permission to be stored on your computer, by accepting said
          file is created and the cookie is then used to have information
          regarding web traffic, and also facilitates future visits to a
          recurring website. Another function that cookies have is that with them
          The websites can recognize you individually and therefore provide you with the
          best personalized service on your website.`,
          `Our website uses cookies to identify the
          pages that are visited and their frequency. This information is
          used only for statistical analysis and then the information
          is permanently removed. You can delete cookies at
          any time from your computer. However, cookies help
          provide better service than websites, these do not give access
          to information from your computer or from you, unless you do so.
          want and provide it directly. You can accept or deny the
          use of cookies, however most browsers accept cookies
          automatically because it serves to have a better web service. You
          You can also change your computer settings to decline
          the cookies. If they decline, you may not be able to use some
          of our services.`,
        ],
      },
      {
        title: "Links to Third Parties",
        description: [
          `This website may contain links to other sites that
          might be of interest to you. Once you click on these links and leave
          our page, we no longer have control over the site to which it is redirected and therefore
          Therefore, we are not responsible for the terms or privacy or the protection of your
          data on those other third party sites. These sites are subject to their own policies
          privacy, so it is recommended that you consult them to confirm that you are
          according to these.`,
        ],
      },
      {
        title: "Control of your personal information",
        description: [
          `At any time you may restrict the collection or use
          of personal information that is provided to our website.
          Every time you are asked to fill out a form, such as the registration form,
          user, you can check or uncheck the option to receive information by
          email. In case you have checked the option to receive
          our newsletter or advertising you can cancel it at any time.
          moment.`,
          `This company will not sell, assign or distribute the information
          personal information that is collected without your consent, unless it is
          required by a judge with a court order.`,
          `LAMBDA reserves the right to change the terms of this
          Privacy Policy at any time.`,
        ],
      },
    ],
  },
  /* ------------------------------- Home ------------------------------- */
  home: {
    title: {
      title: "Predict today\nthe company\nyou want\ntomorrow.",
      description: {
        dataScience: "data science",
        content: "to achieve your goals.",
      },
      button: "SCHEDULE A CALL",
    },
    introduction: {
      beforePotential: "Are you taking advantage of all the",
      potential: "potential",
      afterPotential: "from your data?",
      content: (
        <>
          The data that your company generates daily is <b>very valuable</b>{" "}
          because can be used to know how to manage inventory and not run out
          out of stock, anticipate employee turnover and take actions to retain
          them, or develop marketing strategies to reach your ideal clients.
        </>
      ),
    },
    weAre: {
      title: "We are LAMBDA",
      content: [
        <>
          We are experts in advising SMEs and large companies from different
          industries, to maximize your time and resources using Data Science
          (data science) for predicting data that{" "}
          <span className="lightColor">
            allows you to make more accurate and timely strategic decisions.
          </span>
        </>,
        `We want to innovate in the way we solve business challenges.
        our clients, offering them a wide range of information
        processed for analysis and a differential value to your strategies
        business.`,
      ],
    },
    solutions: {
      title: "Solutions",
      content: [
        `Entrust your data to highly qualified professionals and boost
        extraordinary results: from greater efficiency to increased
        of your income.`,
        <>
          Save time and costs while you take care of the{" "}
          <b>strategic decisions that will grow your business</b> - thanks to
          the results obtained with the help of our predictive models.
        </>,
      ],
      buttons: [
        "Production planning",
        "Prediction of resignations",
        "LAMBDA SAP",
        "Potential customers",
        "Custom solutions",
      ],
      available: {
        productionPlanning: {
          title: "Production planning",
          description: `Imagine being able to know with a high level of confidence how much
          Your business must produce to satisfy demand and not run out of stock,
          or avoid overproduction.`,
          list: [
            "Predict how much you should produce.",
            "Optimize materials and time.",
            "Improves annual resource planning.",
            "Analyze the growth of demand by understanding consumption patterns.",
            "Improve your understanding of the behavior of each product in the market.",
            "Avoid overproduction or running out of stock (efficiency in demand planning).",
          ],
          button: "Plan your production",
        },
        resignationPrediction: {
          title: "Resignation prediction",
          description: (
            <>
              We know that integrating someone into your team requires a lot of
              time. of training and resources, therefore it is much better to
              retain it than hiring someone new. Imagine you can{" "}
              <span className="gothamBold">
                predict when a resignation may occur
              </span>{" "}
              and make decisions on time that allow you to retain your staff,
              and improve the work environment.
            </>
          ),
          list: [
            "You will be able to improve the work environment.",
            "You will make timely decisions for training, promotions and promotions.",
            "You will provide better services to customers, taking care of employee satisfaction.",
            "You will optimize the attraction and selection of human talent.",
          ],
          button: "Predict possible resignations",
        },
        potentialCostumers: {
          title: "Potential clients",
          description: [
            `Think about what it would be like to know with certainty who your customers are.
            potential, and increase your ROAS with your Marketing campaigns.`,
            <>
              We want the investment in your campaigns to have{" "}
              <span className="gothamBold">
                the results you are looking for.{" "}
              </span>{" "}
            </>,
          ],
          list: [
            "You will simplify the segmentation work for the dissemination of your advertising campaigns.",
            "You will optimize the sending and display of your content.",
            "You will save marketing costs.",
          ],
          button: "Identify your ideal clients",
        },
        customizedSolutions: {
          title: "Custom solutions",
          description: [
            `Know the right time to give preventive maintenance to your
          machinery, knowing how much fertilizer and chemicals to use in your
          you sow to maximize your production, or prevent failures in your
          active in anticipation of possible eventualities that could affect your
          productivity, they can save you thousands of dollars and a lot of time.`,
            `We can design for you the predictive model that your company needs
          to optimize your resources and enhance your capacity.`,
          ],
          button: "Let's design your solution",
        },
      },
    },
    testimonials: [
      {
        text: `At the Magdalena Mill we turned to LAMBDA experts for the
          creating analytical tools with precise visualizations and
          friendly that help us understand the causes of variation in the
          production of our farms. Its team of experts allowed us to design
          and display the information in such a way that it is very easy for our
          farmers determine in a logical and orderly manner the causes of
          impact on production variation.`,
        person: "Eng. Mario Sagastume, Agricultural Intelligence Manager.",
        company: "Magdalena Sugar Mill, Guatemala.",
      },
      {
        text: `For almost two years of collaboration with Sci Lambda, we have been
        working on the implementation of MRP in Grupo Pantaleón, a key project for
        the optimization of our processes. From the beginning in February 2023, their
        team demonstrated a high level of expertise, commitment and professionalism,
        guiding us at every stage, from the initial tests to the consolidation of the
        system. Your strategic guidance, recurring reports and proactive approach were
        fundamental to align the management involved and highlight the impact of MRP in
        the organization. I am grateful for the leadership and technical knowledge they
        provided, contributing significantly to the success of this initiative.`,
        person: "Sara Cuevas, Corporate Head of Logistics Planning.",
        company: "Pantaleón Sugar Mill, Guatemala.",
      },
    ],
    companies: {
      title: "Some of the companies that have trusted us",
    },
  },
  /* ------------------------------ We Are ------------------------------ */
  weAre: {
    title: {
      title: "Your time is the most important thing.",
      description: `Every day we make thousands of decisions and many of them are
      irrelevant or of little importance. However, some are decisive
      for you and your company, and getting it right is not always easy.`,
    },
    banners: [
      {
        beforeHighlight: "And",
        highlight: "you shouldn't waste your energy or your time",
        afterHighlight: `thinking about the important decisions you need to make. That's why it is
        It is essential to have an ally in this strategic evaluation process,
        that goes beyond what HR can attract to your business.`,
      },
      {
        title: "That's why we created LAMBDA",
        content: [
          `so that you can make informed decisions with data that allows you
          act strategically and with confidence, and be certain that said
          decisions will have foundation.`,
          `Our team is highly trained in the areas of Mathematics,
          Statistics, Programming, Technology and Systems with an understanding
          deep understanding of the theoretical foundations involved in science
          of data, to convert your data into Insights that help you achieve
          the goals of your company.`,
        ],
      },
    ],
    timeline: [
      "Two Guatemalan scientists decided to study Operations Research.",
      "They began their training and learning in Big Data.",
      `LAMBDA was founded to offer consulting services in data science and
      B2B and B2C consulting in the development of data science algorithms.`,
    ],
  },
  /* ------------------------------- Blogs ------------------------------ */
  blogs: {
    get: {
      ok: "The blogs were found.",
      notFound: "No blog could be found.",
      error: "An error has occurred. Please try again later.",
    },
    post: {
      created: "The blog information has been saved successfully.",
      badRequest: `The blog has not been created because the data does not comply with the
      established requirements.`,
      error: "An error has occurred. Please try again later.",
    },
    title: {
      date: "Upload date",
    },
    infoScreen: {
      title: "Not Found",
      content: [
        `The blog you are looking for has been removed or cannot be found
          temporarily available.`,
        "Take a look at the blogs that are available by clicking",
      ],
      button: "here",
    },
    notFoundScreen: {
      title: "Under construction",
      content: [
        `We are working hard so that on this site you find what you
          you are looking for regarding Data Science. Continue visiting our page to
          learn more about what we do.`,
        `Check back soon for more information and a new brand design.`,
      ],
    },
    topics: [
      "AI",
      "Machine Learning",
      "Deep Learning",
      "Data Analyst",
      "Data Science",
      "Data Engineering",
    ],
    upload: {
      title: "Upload blog",
      description:
        "Upload the blog you want by filling out the following boxes with the corresponding information.",
      error: "The blog could not be uploaded.",
      inputs: {
        title: "Title",
        autoURL: "Generate automatic URL",
        image: "Image",
        password: "Password",
        button: "Upload",
      },
    },
  },
  /* ----------------------------- Solutions ---------------------------- */
  solutions: {
    productionPlanning: {
      title: "Production planning",
      description: "Do you want to plan production to optimize time and costs?",
      type: "MODEL",
      button: "REQUEST THE MODEL",
      banners: [
        {
          title: "Do you want to adopt the best production strategy?",
          content: [
            `The production of any type of good requires in-depth analysis
            that allows you to correctly identify the quantities that must be
            produced,`,
            `to avoid generating losses due to waste or absence of products
            when your clients require it.`,
            `For complex production systems that depend on different
            locations, the best strategy to maximize profits is
            determine a specific quantity of products to be manufactured during
            certain time, and understanding the history of consumption in each case is
            of utmost importance and great value to optimize processes
            productive.`,
          ],
        },
        {
          title: "Do you want your supply chain to be a success?",
          content: [
            `At Lambda, we develop models with advanced statistics and mathematics
            applied to help you with decisions linked to the supply chain
            supply, allowing you to adjust your operations and levels of
            production with greater security.`,
          ],
        },
        {
          title:
            "Do you want to prevent a production failure before it happens?",
          content: [
            "When predicting",
            "when to give preventive maintenance to your machinery",
            `, you can anticipate possible failures of your company's equipment
            that could endanger the entire production.`,
          ],
        },
        {
          title: "How does it work?",
          content: [
            `After training and selection of the best model
            predictive, it is estimated that the solution can reach a confidence
            greater than 90% for future predictions of consumption or demand for
            each product, so the solution is of great help to be able
            apply production metrics for subsequent time periods or
            well annual cost planning depending on the
            necessary quantities of raw materials in the following years.`,
          ],
        },
      ],
      finalSection: {
        title: "",
        button: "REQUEST THE MODEL",
      },
    },
    resignationPrediction: {
      title: "Resignation prediction",
      description:
        "Because retaining a collaborator is much better than recruiting a new one.",
      type: "MODEL",
      button: "REQUEST A CONSULTATION",
      banners: [
        {
          title: "Do you want to improve the work environment?",
          content: [
            `Human resources are the most valuable resource in your business. It's clear
          that being able to predict decisions will be a challenge, given the volatility and
          psychology of human beings, however, is not impossible`,
            `thanks to our model that will help you predict when a
            employee has high chances of quitting.`,
          ],
        },
        {
          title: "Do you want to make better decisions to retain your staff?",
          content: [
            `Determine which variables most affect and lead to the
            resignation of your employees, can help you retain them by applying
            HR techniques, such as training, promotions or promotions.`,
          ],
        },
        {
          title: "How does it work?",
          content: [
            `Our model will take as input important variables that affect
            employee satisfaction such as salary, hours worked,
            department, promotions or promotions, among others. Furthermore, this
            It works assuming that the company has historical data
            related to employees who have left the company; between older
            records are kept, the better results will be obtained.`,
            `This type of solutions can also be used for:`,
          ],
          list: [
            "Hiring new employees.",
            "Optimization of the recruitment process.",
            "Timely analysis of salary increases.",
          ],
        },
      ],
      finalSection: {
        title: "Do you want to retain your employees?",
        button: "WE WILL ADVISE YOU",
      },
    },
    lambdaSAP: {
      title: "Lambda SAP",
      description: "",
      type: "Consulting",
      button: "REQUEST A CONSULTANCY",
      banners: [
        {
          title: "",
          content: [
            `At LAMBDA we have a division specialized in maximizing the use of SAP, helping organizations
            to obtain the maximum benefit from their investment. Our methodology is based on experience,
            best practices and strategic implementation to optimize the use of SAP in all business areas.`,
          ],
        },
        {
          title: "Benefits of Working with LAMBDA",
          list: [
            "Maximization of return on investment (ROI).",
            "Reducing operating costs and improving efficiency.",
            "Strategic leveraging of SAP functionalities",
            "Continuous support by specialized consultants.",
          ],
        },
        {
          title: "Our Team",
          list: [
            "Years of combined experience in SAP consulting.",
            "Specialists in various modules and business processes.",
            "Certified consultants with hands-on experience in multiple industries.",
          ],
        },
        {
          title: "LAMBDA: Your Strategic Partner for SAP Optimization",
          content: [
            `LAMBDA is the ideal partner to optimize SAP and achieve a true digital transformation.
            We have the knowledge, experience and tools to help you get the most out of your SAP investment.`,
          ],
        },
        { title: "Our Value Proposition" },
        {
          title: "1. We take the pain out of using SAP",
          list: [
            "We implement practical strategies to optimize its use and facilitate daily management.",
            "Implementation of “Best Practices” suggested by SAP.",
          ],
        },
        {
          title: "2. LAMBDA's added value.",
          list: [
            "We identify key areas for improvement and provide customized recommendations on how and where to start improving SAP.",
          ],
        },
        {
          title: "3. Experience and knowledge in all modules.",
          list: [
            "From production to finance, we help optimize every process to maximize operational efficiency.",
          ],
        },
        {
          title: "4. We do NOT sell SAP, we are support consultants.",
          list: [
            "We accompany companies in the optimization and exploitation of their SAP investment.",
          ],
        },
        {
          title: '5. Key problem: "You\'ve paid for SAP, now use it well."',
          list: [
            "We help companies get the most out of SAP through best practices and process adoption.",
          ],
        },
        {
          title: "6. Reduction of operating costs.",
          list: [
            "We improve the use of SAP within 8-10 months at an affordable cost.",
          ],
        },
      ],
      finalSection: {
        title: "",
        button: "Use LAMBDA's added value.",
      },
    },
    potentialCustomers: {
      title: "Identification of potential clients",
      description: "",
      type: "MODEL",
      button: "REQUEST THE MODEL",
      banners: [
        {
          title: "",
          content: [
            `We know that it is painful to invest a lot of money in marketing campaigns
            that do not give you the expected results. Segment your customers and know
            Who is a potential client can make a difference.`,
          ],
        },
        {
          title: "Do you want your messages to reach your potential customers?",
          content: [
            `The objective of this project is to be able to simplify the taking of
            decisions and segmentation of advertising or promotions, leaving all the
            work to a predictive model that will return a list of the
            contacts that are considered potential customers and that can be
            include in broadcast list.`,
            `This model will use several characteristics about your current customers,
            to return an identifying variable as a result, and thus know
            whether any contact is a potential client or not.`,
            `This type of big data model could also be applied to:`,
          ],
          list: [
            "Optimization of the customer journey of a web site.",
            "Segmentation of customers who can opt for better services or products.",
            "Optimization of the next product to buy (NPTB) process",
            "Investment analysis and budgets in different marketing channels.",
          ],
        },
      ],
      finalSection: {
        title: "",
        button: "TRY OUR MODEL",
      },
    },
    customizedSolutions: {
      title: "Custom solutions",
      description: "",
      type: "PREDICTIVE MODELS",
      button: "REQUEST A CONSULTATION",
      banners: [
        {
          title: "",
          content: [
            `It means knowing the right time to give preventive maintenance to your
            machinery, knowing how much fertilizer and chemicals to use in your
            you sow to maximize your production, or prevent failures in your
            active in anticipation of possible eventualities that could affect your
            productivity,`,
            `we will find the best solution to your problem and design the
            predictive model that your company needs to optimize your
            resources and enhance your capacity.`,
          ],
        },
        {
          title: "Harness the power of data.",
          content: [
            <>
              With <span className="pirulen">LAMBDA</span> maximize the value of
              your data and transform it into strategic information.
            </>,
          ],
          button: "Request your custom model",
        },
      ],
      finalSection: {
        title: "",
        button: "",
      },
    },
  },
};

export default english;
