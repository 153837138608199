import { useLayoutEffect } from "react";
import { primaryColor } from "../style/colors";
import styled from "styled-components";
import metadata from "../constants/metadata";
import WhatsAppIcon from "../assets/whatsapp-icon.webp";
import EmailIcon from "../assets/email-icon.webp";
import LinkedInIcon from "../assets/linkedin-icon.webp";
import LineDivider from "../components/Utils/LineDivider";
import SEO from "../components/SEO";

function Member({ data }) {
  const phones = data.phones;

  useLayoutEffect(() => {
    window.scroll(-100, -100);
  }, []);

  return (
    <Wrapper id={"initial"}>
      <SEO metadata={metadata[data.url]} />
      <LineDivider width="100%" padding="0" border={`2vw solid #e7f7f8`} />
      <div
        className="whiteBg mobile-section-vertical-padding mobile-section-horizontal-padding"
        style={{ padding: "2% 15% 2% 15% ", margin: "0px", width: "100%" }}
      >
        <div className="row">
          <div className="col-lg">
            <h1
              className={"gothamBold bc-title primaryColor textLeft"}
              style={{ margin: "0px" }}
            >
              <b>{data.name}</b>
            </h1>
            <div className="d-flex justify-content-start align-items-center job-margin">
              <p
                className="bc-job radius6 gothamBold primaryColor mobile-model-padding"
                style={{
                  backgroundColor: "transparent",
                  border: `1px solid ${primaryColor}`,
                  padding: "0.2% 3.5%",
                }}
              >
                {data.job}
              </p>
            </div>
            <div className="br" />
            <div className={"gothamLight font-text primaryColor text-left"}>
              {data.description}
            </div>
            <br />
            <div className="br" />
            {phones.pbx && (
              <>
                <div
                  className={"d-flex justify-content-start align-items-center"}
                >
                  <p
                    className="col-sm gothamLight font-text primaryColor textLeft"
                    style={{ padding: "0" }}
                  >
                    <A
                      className="primaryColor"
                      href={`tel:${phones.pbx.replace(/[()-]/g, "")}`}
                    >
                      PBX: {phones.pbx}
                    </A>
                  </p>
                </div>
                <br />
              </>
            )}
            {phones.linkedin && (
                <>
                  <div
                      className={"d-flex justify-content-start align-items-center"}
                  >
                    <img
                        title="WhatsApp icon"
                        className="icon"
                        loading="lazy"
                        src={LinkedInIcon}
                        alt="WhatsApp icon"
                        width="53px"
                        style={{
                          paddingRight: "1rem",
                          minWidth: "48px",
                          minHeight: "34px",
                        }}
                    />
                    <p
                        className="gothamLight font-text primaryColor"
                        style={{ margin: "0", padding: "0" }}
                    >
                      <A
                          href={`https://${phones.linkedin}`}
                      >
                        LinkedIn/luisguzmangt
                      </A>
                    </p>
                  </div>
                  <br />
                </>
            )}
            {phones.whatsapp && (
              <>
                <div
                  className={"d-flex justify-content-start align-items-center"}
                >
                  <img
                    title="WhatsApp icon"
                    className="icon"
                    loading="lazy"
                    src={WhatsAppIcon}
                    alt="WhatsApp icon"
                    width="53px"
                    style={{
                      paddingRight: "1rem",
                      minWidth: "48px",
                      minHeight: "34px",
                    }}
                  />
                  <p
                    className="gothamLight font-text primaryColor"
                    style={{ margin: "0", padding: "0" }}
                  >
                    <A
                      href={`https://api.whatsapp.com/send?phone=${phones.whatsapp.replace(
                        /[+()\s-]/g,
                        ""
                      )}`}
                    >
                      {phones.whatsapp}
                    </A>
                  </p>
                </div>
                <br />
              </>
            )}
            <div className={"d-flex justify-content-start align-items-center"}>
              <img
                title="Email icon"
                className="icon"
                loading="lazy"
                src={EmailIcon}
                alt="Email icon"
                width="53px"
                style={{
                  paddingRight: "1rem",
                  minWidth: "48px",
                  minHeight: "34px",
                }}
              />
              <p
                className="gothamLight font-text primaryColor"
                style={{ margin: "0", padding: "0" }}
              >
                <A href={`mailto:${data.email}`}>{data.email}</A>
              </p>
            </div>
            <br />
            <div className="br" />
            <div
              className="justify-content-start align-items-center d-none-mobile"
              style={{ width: "400px" }}
            >
              <LineDivider
                width="0%"
                padding="2% 0"
                border={`10px solid #e7f7f8`}
              />
            </div>
          </div>
          <div
            className="col-xxl d-flex justify-content-center align-items-center"
            style={{ padding: "0px", margin: "0px" }}
          >
            <img
              title={data.name}
              className="bc-image"
              loading="lazy"
              src={data.img}
              alt={data.name}
              width="auto"
              height="auto"
            />
          </div>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
  padding: 80px 0 0 0;
`;
const A = styled.a`
  text-decoration: none !important;
  color: ${primaryColor};
`;

export default Member;
