import React from "react";
import { useSelector } from "react-redux";
import { Routes, Route } from "react-router-dom";
import { getContent } from "../constants/languages/languages";

// Screen Sections
import HomeScreen from "./Home/HomeScreen";
import SolutionsScreen from "./Solutions/SolutionsScreen";
import SolutionHandler from "./Solutions/SolutionHandler";
import WeAreScreen from "./WeAre/WeAreScreen";
import BlogInfoScreen from "./Blog/BlogInfoScreen";
import UploadBlogScreen from "./Blog/UploadBlog";
import BlogScreen from "./Blog/BlogScreen";
import NotFound from "./NotFound";
import PrivacyPolicy from "./PrivacyPolicy";
import Member from "./Member";
// import Footer from "./../components/Footer/Footer";
import TopNavbar from "../components/Nav/TopNavbar";
import ToUpArrow from "../components/Nav/ToUpArrow";

import ImgDonhe from "../assets/members/donhe.webp";
import ImgPreng from "../assets/members/preng.webp";
import ImgJorge from "../assets/members/jorge.webp";
import ImgAndres from "../assets/members/andres.webp";
import ImgLuisFer from "../assets/members/luisfer.webp";

export default function Landing() {
  const language = useSelector((state) => state.language.language);
  const content = getContent(language);

  const members = {
    donhe: {
      name: content.businessCards.donhe.name,
      job: content.businessCards.donhe.job,
      description: content.businessCards.donhe.description,
      phones: { pbx: null, linkedin: null, whatsapp: "+(502) 5881-1621" },
      email: "donhe@scilambda.net",
      img: ImgDonhe,
      url: "donhe",
    },
    preng: {
      name: content.businessCards.preng.name,
      job: content.businessCards.preng.job,
      description: content.businessCards.preng.description,
      phones: { pbx: null, linkedin: null, whatsapp: "+(502) 4188-1793" },
      email: "preng@scilambda.net",
      img: ImgPreng,
      url: "preng",
    },
    jorge: {
      name: content.businessCards.jorge.name,
      job: content.businessCards.jorge.job,
      description: content.businessCards.jorge.description,
      phones: { pbx: null, linkedin: null, whatsapp: "+(502) 3019-6312" },
      email: "jorge@scilambda.net",
      img: ImgJorge,
      url: "jorges",
    },
    andres: {
      name: content.businessCards.andres.name,
      job: content.businessCards.andres.job,
      description: content.businessCards.andres.description,
      phones: { pbx: null, linkedin: null, whatsapp: "+(502) 5827-1404" },
      email: "andres@scilambda.net",
      img: ImgAndres,
      url: "andres",
    },
    luisfer: {
      name: content.businessCards.luisfer.name,
      job: content.businessCards.luisfer.job,
      description: content.businessCards.luisfer.description,
      phones: { pbx: null, linkedin: "www.linkedin.com/in/luisguzmangt", whatsapp: "+(502) 5205-7496" },
      email: "luis@scilambda.net",
      img: ImgLuisFer,
      url: "luisfer",
    },
  };

  return (
    <>
      {/* NAV BAR */}
      <Routes>
        <Route path="/contacto" element={<TopNavbar instantPopUp={true} />} />
        <Route path="*" element={<TopNavbar instantPopUp={false} />} />
      </Routes>

      {/* UP ARROW */}
      <ToUpArrow />

      {/* ROUTES */}
      <Routes>
        <Route path="/" element={<HomeScreen />} />
        <Route path="/contacto" element={<HomeScreen />} />
        <Route path="/quienes_somos" element={<WeAreScreen />} />
        <Route path="/blog-upload" element={<UploadBlogScreen />} />
        <Route path="/blog/:url" element={<BlogInfoScreen />} />
        <Route path="/blog" element={<BlogScreen />} />
        <Route path="/politica-de-privacidad" element={<PrivacyPolicy />} />
        <Route path="/Donhe" element={<Member data={members.donhe} />} />
        <Route path="/preng" element={<Member data={members.preng} />} />
        <Route path="/jorges" element={<Member data={members.jorge} />} />
        <Route path="/andres" element={<Member data={members.andres} />} />
        <Route path="/luisfer" element={<Member data={members.luisfer} />} />
        <Route path="/soluciones" element={<SolutionsScreen />} />
        <Route
          path="/soluciones/:solution_name"
          element={<SolutionHandler />}
        />
        <Route path="*" element={<NotFound />} />
      </Routes>

      {/* FOOTER */}
      {/* <Footer /> */}
    </>
  );
}
