const spanish = {
  /* ------------------------------ Nav Bar ----------------------------- */
  navBar: {
    options: ["SOLUCIONES", "QUIÉNES SOMOS", "BLOG", "NUESTRA PLATAFORMA"],
    contact: "CONTACTO",
  },
  /* ------------------------------ Footer ------------------------------ */
  footer: {
    contact: "CONTACTO",
    text: "Todos los derechos reservados.",
    privacyPolicy: "Política de privacidad",
    socialMediaMessages: {
      whatsApp:
        "Hola%21%20Quisiera%20m%C3%A1s%20informaci%C3%B3n%20sobre%20LAMBDA.",
    },
  },
  /* ----------------------------- Not Found ---------------------------- */
  notFound: {
    title: "Página no encontrada",
    content: [
      {
        beforeLambda: `Lo sentimos, no pudimos encontrar la página que solicitaste.
        Intenta buscar algo más o ve a la página principal de`,
        afterLambda: `.`,
      },
      "Continua visitando nuestra página para conocer más sobre lo que hacemos.",
    ],
  },
  /* -------------------------- Business Cards -------------------------- */
  businessCards: {
    donhe: {
      name: "Donhe Fuentes M. Sc.",
      job: "Data Scientist",
      description: `Científico de Datos, con más de 5 años creando soluciones
      para empresas en diferentes industrias. Es ingeniero en
      mecatrónica con una maestría en Investigación de Operaciones.`,
    },
    preng: {
      name: "Preng Biba M. Sc.",
      job: "VP Analytics",
      description: `Full Stack Data Scientist con más de 10 años de
      experiencia. Tiene una maestría en Investigación de Operaciones y
      otra en Sistemas Embebidos, así como una ingeniería en Mecatrónica
      de Universidad Galileo.`,
    },
    jorge: {
      name: "Dr. Jorge Samayoa",
      job: "CEO",
      description: `Científico con más de 20 años de experiencia profesional.
      Tiene un Ph.D. de la universidad de Purdue y una maestría en matemática
      de Texas A&M, así como otra en Investigación de Operaciones de Universidad
      Galileo. Es ingeniero en Electrónica y se ha desarrollado como científico
      y consultor durante su vida profesional.`,
    },
    andres: {
      name: "Andrés Cazali M. Sc.",
      job: "Data Scientist",
      description: `Científico de datos con más de 5 años de experiencia en
      distintas industrias. Es Ingeniero en electrónica y tiene una maestría
      en Ingeniería biomédica de Universidad Galileo. Ha trabajado en el campo
      de la investigación científica para Universidad Galileo en conjunto con
      IMTEK de la Universidad de Freiburg.`,
    },
    luisfer: {
      name: "Ing. Luis Fernando Guzmán",
      job: "Key Account Manager",
      description: `Experto en manejo de cuentas clave, servicio al cliente y
      procesos de ventas. Ingeniero Empresarial de la Universidad Francisco
      Marroquín con una maestría en Administración de operaciones de Universidad
      Galileo. Amplia experiencia en el área de ventas y docencia universitaria.`,
    },
  },
  /* ------------------------------ Contact ----------------------------- */
  contact: {
    title: "Contáctanos",
    description: `Queremos escucharte y ayudarte a encontrar la mejor solución de Data
    Science para alcanzar tus metas.`,
    content: ["Déjanos tus datos y en breve te contactaremos."],
    button: "Enviar",
    inputs: {
      names: "Nombres",
      lastNames: "Apellidos",
      email: "Correo",
      area: "Sector donde trabaja",
      company: "Empresa",
      moreInfo: "Quiero más información de...",
      message: "Tu mensaje",
      phone: "No. de Teléfono",
    },
    areaOptions: [
      "Sector donde trabaja",
      "Administración",
      "Agropecuario",
      "Comercio",
      "Comunicación",
      "Consultoría",
      "Educación",
      "Financiero",
      "Jurídico",
      "Sanidad",
      "Tecnologías de la información y comunicación",
      "Telecomunicaciones",
      "Transporte",
      "Turismo",
      "Otro",
    ],
    moreInfoOptions: [
      "Quiero más información de...",
      "LAMBDA",
      "Soluciones",
      "Asesorías",
      "Cómo puedo trabajar con ustedes",
      "Planeación de la producción",
      "Predicción de renuncias",
      "LAMBDA SAP",
      "Clientes potenciales",
      "Soluciones personalizadas",
      "Otro",
    ],
    defaultMessages: {
      advice: "Deseo una asesoría sobre cómo puedo aprovechar mis datos.",
      productionPlanning:
        "Me gustaría solicitar el modelo de planeación de la producción.",
      resignationPrediction: [
        "Quisiera recibir una asesoría sobre cómo funciona la predicción de renuncia.",
        "Quisiera retener a los empleados de mi empresa.",
      ],
      lambdaSAP: [
        "Me gustaría saber más acerca de la solución LAMBDA SAP.",
        "Quisiera probar la solución LAMBDA SAP en mi empresa.",
      ],
      potentialCustomers: [
        "Me gustaría solicitar el modelo de identificación de clientes potenciales.",
        "Quisiera probar el modelo de identificación de clientes potenciales en mi empresa.",
      ],
      customizedSolutions: [
        "Quisiera recibir una asesoría sobre cómo funciona el modelo de soluciones personalizadas.",
        "Me gustaría usar el modelo de soluciones personalizadas en mi empresa.",
        "Quiero aprovechar la data de mi empresa con este modelo.",
      ],
    },
  },
  /* --------------------------- Message Sent --------------------------- */
  messageSent: {
    success: `Tus datos han sido enviados con éxito a nuestro equipo de profesionales.
        Gracias por confiar en LAMBDA, te estaremos contactando lo más pronto
        posible.`,
    error: `Tus datos no se han podido enviar correctamente. Revisa tu conexión a
        internet y vuelve a intentarlo.`,
  },
  /* -------------------------- Privacy Policy -------------------------- */
  privacyPolicy: {
    title: "Política de privacidad",
    description: `La presente Política de Privacidad establece los términos en que LAMBDA usa y 
      protege la información que es proporcionada por sus usuarios al momento de utilizar su
      sitio web. Esta compañía está comprometida con la seguridad de los datos de sus usuarios.
      Cuando le pedimos llenar los campos de información personal con la cual usted pueda ser identificado,
      lo hacemos asegurando que sólo se empleará de acuerdo con los términos de este documento. Sin embargo, esta
      Política de Privacidad puede cambiar con el tiempo o ser actualizada por lo que le recomendamos y enfatizamos
      revisar continuamente esta página para asegurarse que está de acuerdo con dichos cambios.`,
    sections: [
      {
        title: "Información que es recogida",
        description: [
          `Nuestro sitio web podrá recoger información personal, por ejemplo: nombre, información de contacto
          como su dirección de correo electrónica e información demográfica. Así mismo cuando sea necesario podrá ser requerida
          información específica para procesar algún pedido o realizar una entrega o facturación.`,
        ],
      },
      {
        title: "Uso de la información recogida",
        description: [
          `Nuestro sitio web emplea la información con el fin de proporcionar el
          mejor servicio posible, particularmente para mantener un registro de
          usuarios, de pedidos en caso que aplique, y mejorar nuestros productos
          y servicios. Es posible que sean enviados correos electrónicos
          periódicamente a través de nuestro sitio con ofertas especiales,
          nuevos productos y otra información publicitaria que consideremos
          relevante para usted o que pueda brindarle algún beneficio, estos
          correos electrónicos serán enviados a la dirección que usted
          proporcione y podrán ser cancelados en cualquier momento.`,
          `LAMBDA está altamente comprometido para cumplir con el compromiso de
          mantener su información segura. Usamos los sistemas más avanzados y
          los actualizamos constantemente para asegurarnos que no exista ningún
          acceso no autorizado.`,
        ],
      },
      {
        title: "Cookies",
        description: [
          `Una cookie se refiere a un fichero que es enviado con la finalidad de
          solicitar permiso para almacenarse en su ordenador, al aceptar dicho
          fichero se crea y la cookie sirve entonces para tener información
          respecto al tráfico web, y también facilita las futuras visitas a una
          web recurrente. Otra función que tienen las cookies es que con ellas
          las web pueden reconocerte individualmente y por tanto brindarte el
          mejor servicio personalizado de su web.`,
          `Nuestro sitio web emplea las cookies para poder identificar las
          páginas que son visitadas y su frecuencia. Esta información es
          empleada únicamente para análisis estadístico y después la información
          se elimina de forma permanente. Usted puede eliminar las cookies en
          cualquier momento desde su ordenador. Sin embargo las cookies ayudan a
          proporcionar un mejor servicio de los sitios web, estás no dan acceso
          a información de su ordenador ni de usted, a menos de que usted así lo
          quiera y la proporcione directamente. Usted puede aceptar o negar el
          uso de cookies, sin embargo la mayoría de navegadores aceptan cookies
          automáticamente pues sirve para tener un mejor servicio web. Usted
          también puede cambiar la configuración de su ordenador para declinar
          las cookies. Si se declinan es posible que no pueda utilizar algunos
          de nuestros servicios.`,
        ],
      },
      {
        title: "Enlaces a Terceros",
        description: [
          `Este sitio web pudiera contener enlaces a otros sitios que
          pudieran ser de su interés. Una vez que usted de clic en estos enlaces y abandone
          nuestra página, ya no tenemos control sobre el sitio al que es redirigido y por lo
          tanto, no somos responsables de los términos o privacidad ni de la protección de sus
          datos en esos otros sitios de terceros. Dichos sitios están sujetos a sus propias políticas
          de privacidad por lo cual es recomendable que los consulte para confirmar que usted está
          de acuerdo con estas.`,
        ],
      },
      {
        title: "Control de su información personal",
        description: [
          `En cualquier momento usted puede restringir la recopilación o el uso
          de la información personal que es proporcionada a nuestro sitio web.
          Cada vez que se le solicite rellenar un formulario, como el de alta de
          usuario, puede marcar o desmarcar la opción de recibir información por
          correo electrónico. En caso de que haya marcado la opción de recibir
          nuestro boletín o publicidad usted puede cancelarla en cualquier
          momento.`,
          `Esta compañía no venderá, cederá ni distribuirá la información
          personal que es recopilada sin su consentimiento, salvo que sea
          requerido por un juez con una orden judicial.`,
          `LAMBDA se reserva el derecho de cambiar los términos de la presente
          Política de Privacidad en cualquier momento.`,
        ],
      },
    ],
  },
  /* ------------------------------- Home ------------------------------- */
  home: {
    title: {
      title: "Predice hoy\nla empresa que\nquieres mañana.",
      description: {
        dataScience: "ciencia de datos",
        content: "para alcanzar tus metas.",
      },
      button: "AGENDAR UNA LLAMADA",
    },
    introduction: {
      beforePotential: "¿Estás aprovechando todo el",
      potential: "potencial",
      afterPotential: "de tus datos?",
      content: (
        <>
          La data que tu empresa genera diariamente es <b>muy valiosa</b> pues
          puede ser usada para saber cómo gestionar el inventario y no quedarte
          sin stock, prever la rotación de empleados y tomar acciones para
          retenerlos, o desarrollar estrategias de marketing para alcanzar a sus
          clientes ideales.
        </>
      ),
    },
    weAre: {
      title: "Somos LAMBDA",
      content: [
        <>
          Somos expertos en asesorar a Pymes y grandes empresas de diferentes
          industrias, para maximizar su tiempo y recursos usando Data Science
          (ciencia de datos) para la predicción de datos que les{" "}
          <span className="lightColor">
            permita tomar decisiones estratégicas más acertadas y oportunas.
          </span>
        </>,
        `Queremos innovar en la manera de resolver los retos de negocios de
        nuestros clientes, ofreciéndoles una amplia gama de información
        procesada para análisis y un valor diferencial a sus estrategias de
        negocio.`,
      ],
    },
    solutions: {
      title: "Soluciones",
      content: [
        `Confía tus datos a profesionales altamente cualificados e impulsa
        resultados extraordinarios: desde una mayor eficiencia hasta el aumento
        de tus ingresos.`,
        <>
          Ahorra tiempo y costos mientras te encargas de tomar las{" "}
          <b>decisiones estratégicas que harán crecer tu negocio</b> - gracias a
          los resultados obtenidos con ayuda de nuestros modelos predictivos.
        </>,
      ],
      buttons: [
        "Planeación de la producción",
        "Predicción de renuncias",
        "LAMBDA SAP",
        "Clientes potenciales",
        "Soluciones personalizadas",
      ],
      available: {
        productionPlanning: {
          title: "Planeación de la producción",
          description: `Imagina poder saber con un alto nivel de confianza cuánto
          debe producir tu negocio para satisfacer la demanda y no quedarse sin stock,
          o evitar la sobreproducción.`,
          list: [
            "Predice cuánto deberías producir.",
            "Optimiza materiales y tiempo.",
            "Mejora la planeación anual de recursos.",
            "Analiza el crecimiento de la demanda entendiendo los patrones de consumo.",
            "Mejora tu comprensión del comportamiento de cada producto en el mercado.",
            "Evita la sobreproducción o quedarte sin stock (eficiencia en la planificación de la demanda).",
          ],
          button: "Planifica tu producción",
        },
        resignationPrediction: {
          title: "Predicción de renuncias",
          description: (
            <>
              Sabemos que integrar a alguien a tu equipo requiere mucho tiempo
              de capacitación y recursos, por lo tanto es mucho mejor retenerlo
              que contratar a alguien nuevo. Imagina que puedes{" "}
              <span className="gothamBold">
                predecir cuándo puede darse una renuncia
              </span>{" "}
              y tomar decisiones a tiempo que te permitan retener a tu personal,
              y mejorar el clima laboral.
            </>
          ),
          list: [
            "Podrás mejorar el clima laboral.",
            "Tomarás decisiones oportunas para capacitaciones, promociones y ascensos.",
            "Brindarás mejores servicios a los clientes, cuidando la satisfacción de los empleados.",
            "Optimizarás la atracción y selección de talento humano.",
          ],
          button: "Predice posibles renuncias",
        },
        potentialCostumers: {
          title: "Clientes potenciales",
          description: [
            `Piensa como sería saber con certeza quiénes son tus clientes
            potenciales, y que aumentes tu ROAS con tus campañas de Marketing.`,
            <>
              Queremos que la inversión en tus campañas tenga{" "}
              <span className="gothamBold">los resultados que buscas. </span>{" "}
            </>,
          ],
          list: [
            "Simplificarás el trabajo de segmentación para difusión de tus campañas publicitarias.",
            "Optimizarás el envío y visualización de tu contenido.",
            "Ahorrarás costos de marketing.",
          ],
          button: "Identifica a tus clientes ideales",
        },
        customizedSolutions: {
          title: "Soluciones personalizadas",
          description: [
            `Conocer el momento oportuno para dar mantenimiento preventivo a tu
          maquinaria, saber cuánto fertilizante y químicos utilizar en tus
          siembras para maximizar tu producción, o prevenir fallos en tus
          activos anticipándote a posibles eventualidades que podrían afectar tu
          productividad, pueden ahorrarte miles de dólares y mucho tiempo.`,
            `Podemos diseñar para ti el modelo predictivo que tu empresa necesita
          para optimizar tus recursos y potenciar tu capacidad.`,
          ],
          button: "Diseñemos tu solución",
        },
      },
    },
    testimonials: [
      {
        text: `En el Ingenio Magdalena acudimos a los expertos de LAMBDA para la
          creación de herramientas analíticas con visualizaciones precisas y
          amigables que nos ayuden a entender las causas de variación de la
          producción de nuestras fincas. Su equipo de expertos permitió diseñar
          y mostrar la información de manera tal que sea muy fácil para nuestros
          agricultores determinar de forma lógica y ordenada las causas de
          impacto en la variación de producción.`,
        person: "Ing. Mario Sagastume, Gerente de Inteligencia Agrícola.",
        company: "Ingenio Magdalena, Guatemala.",
      },
      {
        text: `Durante casi dos años de colaboración con Sci Lambda, hemos trabajado
        en la implementación del MRP en Grupo Pantaleón, un proyecto clave para la
        optimización de nuestros procesos. Desde el inicio en febrero de 2023, su
        equipo demostró un alto nivel de expertise, compromiso y profesionalismo,
        guiándonos en cada etapa, desde las pruebas iniciales hasta la consolidación
        del sistema. Su acompañamiento estratégico, informes recurrentes y enfoque
        proactivo fueron fundamentales para alinear a las Gerencias involucradas y
        resaltar el impacto del MRP en la organización. Agradezco el liderazgo y el
        conocimiento técnico que aportaron, contribuyendo significativamente al éxito
        de esta iniciativa.`,
        person: "Sara Cuevas, Jefe Corporativo de Planificación de Logística.",
        company: "Ingenio Pantaleón, Guatemala.",
      },
    ],
    companies: {
      title: "Algunas de las empresas que han confiado en nosotros",
    },
  },
  /* ------------------------------ We Are ------------------------------ */
  weAre: {
    title: {
      title: "Tu tiempo es lo más importante.",
      description: `Cada día tomamos miles de decisiones y muchas de ellas son
      irrelevantes o tienen poca importancia. Sin embargo, algunas son decisivas
      para ti y tu empresa, y acertar no siempre es fácil.`,
    },
    banners: [
      {
        beforeHighlight: "Y",
        highlight: "no deberías perder el sueño ni tu tiempo",
        afterHighlight: `pensando en las decisiones importantes que debes tomar. Por eso es
        indispensable tener un aliado en este proceso estratégico de evaluación,
        que va más allá de lo que RRHH pueda atraer a tu negocio.`,
      },
      {
        title: "Por eso hemos creado LAMBDA,",
        content: [
          `para que tomes decisiones informadas y con datos que te permitan
          actuar estratégicamente y con confianza, y tengas certeza que dichas
          decisiones tendrán fundamento.`,
          `Nuestro equipo está altamente capacitado en las áreas de Matemática,
          Estadística, Programación, Tecnología y Sistemas con una comprensión
          profunda de los fundamentos teóricos que se involucran en la ciencia
          de datos, para convertir tu data en Insights que te ayuden a alcanzar
          las metas de tu empresa.`,
        ],
      },
    ],
    timeline: [
      "Dos científicos guatemaltecos decidieron estudiar Investigación de Operaciones.",
      "Iniciaron su capacitación y aprendizaje en Big Data.",
      `Se fundó LAMBDA para ofrecer servicios de consultoría en ciencia de datos y
      consulting B2B y B2C en la elaboración de algoritmos de ciencia de datos.`,
    ],
  },
  /* ------------------------------- Blogs ------------------------------ */
  blogs: {
    get: {
      ok: "Los blogs fueron encontrados.",
      notFound: "Ningún blog pudo ser encontrado.",
      error: "Ha ocurrido un error. Vuelva a intentarlo más tarde.",
    },
    post: {
      created: "La información del blog ha sido guardada con éxito.",
      badRequest: `El blog no ha sido creado debido a que los datos no cumplen con los
      requerimientos establecidos.`,
      error: "Ha ocurrido un error. Vuelva a intentarlo más tarde.",
    },
    title: {
      date: "Fecha de subida",
    },
    infoScreen: {
      title: "No encontrado",
      content: [
        `El blog que estás buscando ha sido removido o no se encuentra
          disponible temporalmente.`,
        "Echa un vistazo a los blogs que se tienen disponibles haciendo clic",
      ],
      button: "aquí",
    },
    notFoundScreen: {
      title: "En construcción",
      content: [
        `Estamos trabajando duro para que en este sitio encuentres lo que
          buscas respecto a Data Science. Continúa visitando nuestra página para
          conocer más sobre lo que hacemos.`,
        `Vuelve pronto para encontrar mayor información y un diseño
          completamente nuevo.`,
      ],
    },
    topics: [
      "IA",
      "Machine Learning",
      "Deep Learning",
      "Data Analyst",
      "Data Science",
      "Data Engineering",
    ],
    upload: {
      title: "Subir blog",
      description:
        "Suba el blog que desee llenando los siguientes cuadros con la información correspondiente.",
      error: "El blog no pudo ser subido.",
      inputs: {
        title: "Título",
        autoURL: "Generar URL automático",
        image: "Imagen",
        password: "Contraseña",
        button: "Subir",
      },
    },
  },
  /* ----------------------------- Solutions ---------------------------- */
  solutions: {
    productionPlanning: {
      title: "Planeación de la producción",
      description:
        "¿Quieres planificar la producción para optimizar tiempo y costos?",
      type: "MODELO",
      button: "SOLICITA EL MODELO",
      banners: [
        {
          title: "¿Deseas adoptar la mejor estrategia de producción?",
          content: [
            `La producción de cualquier tipo de bien requiere un análisis profundo
            que permita identificar correctamente las cantidades que deben ser
            producidas,`,
            `para no generar pérdidas por desperdicios o ausencia de productos
            cuando tus clientes lo requieren.`,
            `Para sistemas de producción complejos que dependen de distintas
            ubicaciones, la mejor estrategia para maximizar las ganancias es
            determinar una cantidad específica de productos a fabricar durante
            cierto tiempo, y comprender la historia del consumo de en cada caso es
            de suma importancia y de gran valor para optimizar los procesos
            productivos.`,
          ],
        },
        {
          title: "¿Quieres que tu cadena de suministro sea todo un éxito?",
          content: [
            `En Lambda, desarrollamos modelos con estadística avanzada y matemática
            aplicada para ayudarte con las decisiones ligadas a la cadena de
            suministro, permitiéndote ajustar tus operaciones y niveles de
            producción con mayor seguridad.`,
          ],
        },
        {
          title: "¿Deseas evitar un fallo de producción antes de que ocurra?",
          content: [
            `Al predecir`,
            `cuándo dar mantenimiento preventivo a tu maquinaria`,
            `, puedes anticiparte a posibles fallos de los equipos de tu empresa
            ue podrían poner en peligro toda la producción.`,
          ],
        },
        {
          title: "¿Cómo funciona?",
          content: [
            `Después del entrenamiento y de la selección del mejor modelo
            predictivo, se estima que la solución puede alcanzar una confianza
            superior al 90% para predicciones futuras de los consumos o demanda de
            cada producto, por lo que la solución es de gran ayuda para poder
            aplicar métricas de producción para siguientes periodos de tiempo o
            bien planificaciones anuales de costos en dependencia de las
            cantidades necesarias de materias primas en los siguientes años.`,
          ],
        },
      ],
      finalSection: {
        title: "",
        button: "SOLICITA EL MODELO",
      },
    },
    resignationPrediction: {
      title: "Predicción de renuncia",
      description:
        "Porque retener a un colaborador es mucho mejor que reclutar uno nuevo.",
      type: "MODELO",
      button: "SOLICITA UNA ASESORÍA",
      banners: [
        {
          title: "¿Deseas mejorar el clima laboral?",
          content: [
            `El recurso humano es el recurso más valioso en tu negocio. Está claro
          que poder predecir decisiones será un reto, dado la volatilidad y
          psicología de los seres humanos, sin embargo, no es imposible`,
            `gracias a nuestro modelo que te ayudará a predecir cuándo un
            empleado tiene altas posibilidades de renunciar.`,
          ],
        },
        {
          title:
            "¿Quieres tomar mejores decisiones para retener a tu personal?",
          content: [
            `Determinar qué variables son las que más afectan y conllevan a la
            renuncia de tus empleados, puede ayudarte a retenerlos aplicando
            técnicas de RRHH, como capacitaciones, promociones o ascensos.`,
          ],
        },
        {
          title: "¿Cómo funciona?",
          content: [
            `Nuestro modelo tomará como entrada variables importantes que afectan a
            la satisfacción de los empelados como el salario, horas trabajadas,
            departamento, promociones o ascensos, entre otros. Además, este
            funciona asumiendo que la empresa cuenta con data histórica
            relacionada con los empleados que han dejado la empresa; entre mayores
            registros se tengan, mejores resultados se obtendrán.`,
            `Este tipo de soluciones también pueden ser utilizadas para:`,
          ],
          list: [
            "La contratación de nuevos empleados.",
            "Optimización del proceso de reclutamiento.",
            "Análisis oportuno de aumentos salariales.",
          ],
        },
      ],
      finalSection: {
        title: "¿Quieres retener a tus empleados?",
        button: "NOSOTROS TE ASESORAMOS",
      },
    },
    lambdaSAP: {
      title: "Lambda SAP",
      description: "",
      type: "Consultoría",
      button: "SOLICITA UNA ASESORÍA",
      banners: [
        {
          title: "",
          content: [
            `En LAMBDA poseemos una división especializada en maximizar el uso de SAP, ayudando
            a las organizaciones a obtener el máximo beneficio de su inversión. Nuestra metodología
            se basa en la experiencia, las mejores prácticas y la implementación estratégica para
            optimizar el uso de SAP en todas las áreas de negocio.`,
          ],
        },
        {
          title: "Beneficios de Trabajar con LAMBDA",
          list: [
            "Maximización del retorno de inversión (ROI).",
            "Reducción de costos operativos y mejora de eficiencia.",
            "Aprovechamiento estratégico de las funcionalidades de SAP.",
            "Soporte continuo por consultores especializados.",
          ],
        },
        {
          title: "Nuestro Equipo",
          list: [
            "Años de experiencia combinada en consultoría SAP.",
            "Especialistas en diversos módulos y procesos empresariales.",
            "Consultores certificados con experiencia práctica en múltiples industrias.",
          ],
        },
        {
          title: "LAMBDA: Tu Socio Estratégico para Optimizar SAP",
          content: [
            `LAMBDA es el socio ideal para optimizar SAP y lograr una verdadera transformación digital.
            Contamos con el conocimiento, la experiencia y las herramientas para ayudarte a aprovechar
            al máximo tu inversión en SAP.`,
          ],
        },
        { title: "Nuestra propuesta de Valor" },
        {
          title: "1. Eliminamos el dolor de usar SAP",
          list: [
            "Implementamos estrategias prácticas para optimizar su uso y facilitar la gestión diaria.",
            "Implementación de “Best Practices” sugeridas por SAP.",
          ],
        },
        {
          title: "2. Valor agregado de LAMBDA.",
          list: [
            "Identificamos áreas clave de mejora y ofrecemos recomendaciones personalizadas sobre cómo y dónde empezar a mejorar SAP.",
          ],
        },
        {
          title: "3. Experiencia y conocimiento en todos los módulos.",
          list: [
            "Desde producción hasta finanzas, ayudamos a optimizar cada proceso para maximizar la eficiencia operativa.",
          ],
        },
        {
          title: "4. No vendemos SAP, somos consultores de apoyo.",
          list: [
            "Acompañamos a las empresas en la optimización y explotación de su inversión en SAP.",
          ],
        },
        {
          title: '5. Problema clave: "Ya pagaste SAP, ahora úsalo bien."',
          list: [
            "Ayudamos a las empresas a obtener el máximo rendimiento de SAP mediante mejores prácticas y adopción de procesos.",
          ],
        },
        {
          title: "6. Reducción de costos operativos.",
          list: [
            "Mejoramos el uso de SAP en un plazo de 8-10 meses a un costo accesible.",
          ],
        },
      ],
      finalSection: {
        title: "",
        button: "Usa el valor agregado de LAMBDA.",
      },
    },
    potentialCustomers: {
      title: "Identificación de clientes potenciales",
      description: "",
      type: "MODELO",
      button: "SOLICITA EL MODELO",
      banners: [
        {
          title: "",
          content: [
            `Sabemos que es doloroso invertir mucho dinero en campañas de marketing
            que no te dan los resultados esperados. Segmentar a tus clientes y saber
            quién sí es un cliente potencial, puede hacer la diferencia.`,
          ],
        },
        {
          title:
            "¿Quieres que tus mensajes lleguen a tus clientes potenciales?",
          content: [
            `El objetivo de este proyecto es poder simplificar la toma de
            decisiones y segmentación de publicidad o promociones, dejando todo el
            trabajo a un modelo predictivo que te devolverá un listado de los
            contactos que se consideran clientes potenciales y que se pueden
            incluir en la lista de difusión.`,
            `Este modelo usará varias características sobre tus clientes actuales,
            para devolver como resultado una variable identificatoria, y así saber
            si algún contacto es cliente potencial o no.`,
            `Este tipo de modelo de big data también podría aplicarse a:`,
          ],
          list: [
            "Optimización de customer journey de una web site.",
            "Segmentación de clientes que puedan optar por mejores servicios o productos.",
            "Optimización del proceso de next product to buy (NPTB).",
            "Análisis de inversión y presupuestos en diferentes canales de marketing.",
          ],
        },
      ],
      finalSection: {
        title: "",
        button: "PRUEBA NUESTRO MODELO",
      },
    },
    customizedSolutions: {
      title: "Soluciones personalizadas",
      description: "",
      type: "MODELOS PREDICTIVOS",
      button: "SOLICITA UNA ASESORÍA",
      banners: [
        {
          title: "",
          content: [
            `Sea conocer el momento oportuno para dar mantenimiento preventivo a tu
            maquinaria, saber cuánto fertilizante y químicos utilizar en tus
            siembras para maximizar tu producción, o prevenir fallos en tus
            activos anticipándote a posibles eventualidades que podrían afectar tu
            productividad,`,
            `encontraremos la mejor solución a tu problema y diseñaremos el
            modelo predictivo que tu empresa necesita para optimizar tus
            recursos y potenciar tu capacidad.`,
          ],
        },
        {
          title: "Aprovecha el poder de los datos.",
          content: [
            <>
              Con <span className="pirulen">LAMBDA</span> maximiza el valor de
              tus datos y transfórmalos en información estratégica.
            </>,
          ],
          button: "Solicita tu modelo personalizado",
        },
      ],
      finalSection: {
        title: "",
        button: "",
      },
    },
  },
};

export default spanish;
