const baseURL = "https://scilambda.net";

const metadata = {
  home: {
    title: "Scilambda - Data Science, Consulting Services",
    description: `Expertos asesorando empresas de Guatemala y Latinoamérica en servicios
    de consultoría, usando modelos matemáticos y Data Science para la predicción de datos.`,
    href: `${baseURL}`,
  },
  contact: {
    title: "Contacto",
    description:
      "Queremos escucharte y ayudarte a encontrar la mejor solución de Data Science para alcanzar tus metas.",
    href: `${baseURL}/contacto`,
  },
  weAre: {
    title: "Quiénes somos",
    description: `Por eso hemos creado LAMBDA, para que tomes decisiones informadas y con datos que te
    permitan actuar estratégicamente y con confianza.`,
    href: `${baseURL}/quienes_somos`,
  },
  blog: {
    title: "Blogs",
    description:
      "Infórmate de todo lo que necesitas sobre ciencia de datos para que tu negocio prospere como nunca antes.",
    href: `${baseURL}/blog`,
  },
  privacyPolicy: {
    title: "Política de Privacidad",
    description: `La presente Política de Privacidad establece los términos en que LAMBDA usa y protege la información
    que es proporcionada por sus usuarios al momento...`,
    href: `${baseURL}/politica-de-privacidad`,
  },
  donhe: {
    title: "Donhe Fuentes M. Sc.",
    description: `Científico de Datos, con más de 5 años creando soluciones para empresas en diferentes industrias.
    Es ingeniero en mecatrónica con una maestría en...`,
    href: `${baseURL}/Donhe`,
  },
  preng: {
    title: "Preng Biba M. Sc.",
    description: `Full Stack Data Scientist con más de 10 años de experiencia. Tiene una maestría en Investigación
    de Operaciones y otra en Sistemas Embebidos, así como una...`,
    href: `${baseURL}/preng`,
  },
  jorges: {
    title: "Dr. Jorge Samayoa",
    description: `Científico con más de 20 años de experiencia profesional. Tiene un Ph.D. de la universidad de
    Purdue y una maestría en matemática de Texas A&M, así como otra...`,
    href: `${baseURL}/jorges`,
  },
  andres: {
    title: "Andrés Cazali M. Sc.",
    description: `Científico de datos con más de 5 años de experiencia en distintas industrias. Es Ingeniero en
    electrónica y tiene una maestría en Ingeniería biomédica de Universidad...`,
    href: `${baseURL}/andres`,
  },
  luisfer: {
    title: "Ing. Luis Guzmán",
    description: `Experto en manejo de cuentas clave, servicio al cliente y procesos de ventas. Ingeniero Empresarial
    de la Universidad Francisco Marroquín con una maestría en Administración...`,
    href: `${baseURL}/luisfer`,
  },
  solutions: {
    title: "Soluciones",
    description: `Confía tus datos a profesionales altamente cualificados e impulsa resultados extraordinarios:
    desde una mayor eficiencia hasta el aumento de tus ingresos.`,
    href: `${baseURL}/soluciones`,
  },
  customizedSolutions: {
    title: "Soluciones personalizadas",
    description: `Sea conocer el momento oportuno para dar mantenimiento preventivo a tu maquinaria, saber
    cuánto fertilizante y químicos utilizar en tus siembras para maximizar...`,
    href: `${baseURL}/soluciones/soluciones_personalizadas`,
  },
  productionPlanning: {
    title: "Planeación de la producción",
    description: `La producción de cualquier tipo de bien requiere un análisis profundo que permita identificar
    correctamente las cantidades que deben ser producidas, para no...`,
    href: `${baseURL}/soluciones/planeacion_de_la_produccion`,
  },
  resignationPrediction: {
    title: "Predicción de renuncia",
    description: `El recurso humano es el recurso más valioso en tu negocio. Está claro que poder predecir
    decisiones será un reto, dado la volatilidad y psicología...`,
    href: `${baseURL}/soluciones/prediccion_de_renuncias`,
  },
  lambdaSAP: {
    title: "LAMBDA SAP",
    description: `En LAMBDA poseemos una división especializada en maximizar el uso de SAP, ayudando a las
    organizaciones a obtener el máximo beneficio de su inversión...`,
    href: `${baseURL}/soluciones/clientes_potenciales`,
  },
  potentialCustomers: {
    title: "Clientes potenciales",
    description: `El objetivo de este proyecto es poder simplificar la toma de decisiones y segmentación de
    publicidad o promociones, dejando todo el trabajo a un modelo...`,
    href: `${baseURL}/soluciones/clientes_potenciales`,
  },
  notFound: {
    title: "Página no encontrada",
    description: `Lo sentimos, no pudimos encontrar la página que solicitaste. Intenta buscar algo más o ve a
    la página principal de LAMBDA.`,
  },
};

export default metadata;
