import { useLayoutEffect, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { getContent } from "../../../constants/languages/languages";
import styled from "styled-components";
import metadata from "../../../constants/metadata";
import SEO from "../../../components/SEO";

// Facebook Pixel
import { viewContentEvent } from "../../../constants/facebookPixelEvent";

// COMPONENTS
import TitleImage from "../../../components/Screens/Solutions/TitleImage";
import InfoImage from "../../../components/Screens/Solutions/InfoImage";
import Info from "../../../components/Screens/Solutions/Info";

// MODAL
import Contact from "../../../components/Modal/Contact";
import DataSent from "../../../components/Modal/DataSent";
import DataSentError from "../../../components/Modal/DataSentError";
import Backdrop from "../../../components/Elements/Backdrop";
import { messageOptions } from "../../../constants/contactOptions";

// BUTTON
import Solution from "../../../components/Screens/Solutions/Solution";

// ASSETS
import Img1 from "../../../assets/Soluciones/LambdaSAP/1.webp";
import Img2 from "../../../assets/Soluciones/LambdaSAP/2.webp";
import Img3 from "../../../assets/Soluciones/LambdaSAP/3.webp";
import Img4 from "../../../assets/Soluciones/LambdaSAP/4.webp";
import Img5 from "../../../assets/Soluciones/LambdaSAP/5.webp";
import Img6 from "../../../assets/Soluciones/LambdaSAP/6.webp";
import ImgPartner from "../../../assets/Soluciones/LambdaSAP/partner.webp";
import ImgTeam from "../../../assets/Soluciones/LambdaSAP/team.webp";
import ImgBenefits from "../../../assets/Soluciones/LambdaSAP/benefits.webp";

// COLORS
import { whiteBg, lightBg, secondaryColor } from "../../../style/colors";

// ALIGNS
import { textLeft, textCenter, textRight } from "../../../style/aligns";

// CONSTANTS
const titleClass = "gothamBold font-title line-height-100 primaryColor";
const descriptionClass = "gothamLight font-text blackColor";
//const boldClass = "gothamBold";
// const lightClass = "lightColor";

function LambdaSAP(props) {
  useLayoutEffect(() => {
    window.scroll(-100, -100);
  }, []);
  useEffect(() => {
    viewContentEvent();
  }, []);

  const [contactOpen, toggleContact] = useState(false);
  const [dataSentOpen, toggleDataSent] = useState(false);
  const [dataSentErrorOpen, toggleDataSentError] = useState(false);
  const language = useSelector((state) => state.language.language);
  const content = getContent(language);

  const callbackFunction = (id) => {
    toggleContact(!contactOpen);
  };

  const initialBanner = {
    titleImage: {
      type: content.solutions.lambdaSAP.type,
      title: content.solutions.lambdaSAP.title,
      subTitle: content.solutions.lambdaSAP.description,
      image: {
        src: "/Soluciones/LambdaSAP/cover.webp",
        alt: "cover",
      },
      button: {
        title: content.solutions.lambdaSAP.button,
        hover: "#ffb530",
        messageOption: messageOptions(content).lambdaSAP[0],
      },
    },
  };

  const introduction = {
    background: whiteBg,
    title: content.solutions.lambdaSAP.banners[0].title,
    titleClass: "",
    description: content.solutions.lambdaSAP.banners[0].content[0],
    descriptionClass: descriptionClass,
    align: textCenter,
  };
  const listTitle = {
    background: lightBg,
    title: content.solutions.lambdaSAP.banners[4].title,
    titleClass: titleClass,
    description: "",
    descriptionClass: descriptionClass,
    align: textCenter,
  };

  const list1 = {
    background: whiteBg,
    title: "",
    titleClass: "",
    description: (
      <div>
        <ol className={descriptionClass} style={{ lineHeight: "150%" }}>
          {content.solutions.lambdaSAP.banners[1].list.map((item, index) => (
            <li key={`l1${index}`}>{item}</li>
          ))}
        </ol>
      </div>
    ),
    descriptionClass: descriptionClass,
    align: textLeft,
    padding: "0% 15% 5% 15%",
    isNotP: true,
  };

  const list2 = {
    background: whiteBg,
    title: "",
    titleClass: "",
    description: (
      <div>
        <ol className={descriptionClass} style={{ lineHeight: "150%" }}>
          {content.solutions.lambdaSAP.banners[2].list.map((item, index) => (
            <li key={`l2${index}`}>{item}</li>
          ))}
        </ol>
      </div>
    ),
    descriptionClass: descriptionClass,
    align: textLeft,
    padding: "0% 15% 5% 15%",
    isNotP: true,
  };

  const banners = [
    {
      id: 1,
      background: lightBg,
      txtAlign: textLeft,
      title: content.solutions.lambdaSAP.banners[5].title,
      titleClass: titleClass,
      description: (
        <div>
          <br />
          <ol className={descriptionClass} style={{ lineHeight: "150%" }}>
            {content.solutions.lambdaSAP.banners[5].list.map((item, index) => (
              <li key={`1${index}`}>{item}</li>
            ))}
          </ol>
        </div>
      ),
      rightImg: false,
      img: Img1,
      padding: "0px 15% 6rem 15%",
      imgPadding: { padding: "0 15%", maxWidth: "100%" },
    },
    {
      id: 1,
      background: lightBg,
      txtAlign: textLeft,
      title: content.solutions.lambdaSAP.banners[6].title,
      titleClass: titleClass,
      description: (
        <div>
          <ol className={descriptionClass} style={{ lineHeight: "150%" }}>
            {content.solutions.lambdaSAP.banners[6].list.map((item, index) => (
              <li key={`2${index}`}>{item}</li>
            ))}
          </ol>
        </div>
      ),
      rightImg: true,
      img: Img2,
      padding: "0px 15% 6rem 15%",
      imgPadding: { padding: "0 15%", maxWidth: "100%" },
    },
    {
      id: 1,
      background: lightBg,
      txtAlign: textLeft,
      title: content.solutions.lambdaSAP.banners[7].title,
      titleClass: titleClass,
      description: (
        <div>
          <ol className={descriptionClass} style={{ lineHeight: "150%" }}>
            {content.solutions.lambdaSAP.banners[7].list.map((item, index) => (
              <li key={`3${index}`}>{item}</li>
            ))}
          </ol>
        </div>
      ),
      rightImg: false,
      img: Img3,
      padding: "0px 15% 6rem 15%",
      imgPadding: { padding: "0 15%", maxWidth: "100%" },
    },
    {
      id: 1,
      background: lightBg,
      txtAlign: textLeft,
      title: content.solutions.lambdaSAP.banners[8].title,
      titleClass: titleClass,
      description: (
        <div>
          <ol className={descriptionClass} style={{ lineHeight: "150%" }}>
            {content.solutions.lambdaSAP.banners[8].list.map((item, index) => (
              <li key={`4${index}`}>{item}</li>
            ))}
          </ol>
        </div>
      ),
      rightImg: true,
      img: Img4,
      padding: "0px 15% 6rem 15%",
      imgPadding: { padding: "0 15%", maxWidth: "100%" },
    },
    {
      id: 1,
      background: lightBg,
      txtAlign: textLeft,
      title: content.solutions.lambdaSAP.banners[9].title,
      titleClass: titleClass,
      description: (
        <div>
          <ol className={descriptionClass} style={{ lineHeight: "150%" }}>
            {content.solutions.lambdaSAP.banners[9].list.map((item, index) => (
              <li key={`5${index}`}>{item}</li>
            ))}
          </ol>
        </div>
      ),
      rightImg: false,
      img: Img5,
      padding: "0px 15% 6rem 15%",
      imgPadding: { padding: "0 15%", maxWidth: "100%" },
    },
    {
      id: 1,
      background: lightBg,
      txtAlign: textLeft,
      title: content.solutions.lambdaSAP.banners[10].title,
      titleClass: titleClass,
      description: (
        <div>
          <ol className={descriptionClass} style={{ lineHeight: "150%" }}>
            {content.solutions.lambdaSAP.banners[10].list.map((item, index) => (
              <li key={`6${index}`}>{item}</li>
            ))}
          </ol>
        </div>
      ),
      rightImg: true,
      img: Img6,
      padding: "0px 15% 6rem 15%",
      imgPadding: { padding: "0 15%", maxWidth: "100%" },
    },
    {
      id: 1,
      background: whiteBg,
      txtAlign: textLeft,
      title: content.solutions.lambdaSAP.banners[1].title,
      titleClass: titleClass,
      description: (
        <Info
          background={list1.background}
          title={list1.title}
          titleClass={list1.titleClass}
          description={list1.description}
          descriptionClass={list1.descriptionClass}
          align={list1.align}
          padding={list1.padding}
          isNotP={list1.isNotP}
        />
      ),
      rightImg: false,
      img: ImgBenefits,
      imgPadding: { padding: "0 15%", maxWidth: "100%" },
    },
    {
      id: 2,
      background: whiteBg,
      txtAlign: textRight,
      title: content.solutions.lambdaSAP.banners[2].title,
      titleClass: titleClass,
      description: (
        <Info
          background={list2.background}
          title={list2.title}
          titleClass={list2.titleClass}
          description={list2.description}
          descriptionClass={list2.descriptionClass}
          align={list2.align}
          padding={list2.padding}
          isNotP={list2.isNotP}
        />
      ),
      rightImg: true,
      img: ImgTeam,
      imgPadding: { padding: "0 15%", maxWidth: "100%" },
    },
    {
      id: 3,
      background: lightBg,
      txtAlign: textLeft,
      title: content.solutions.lambdaSAP.banners[3].title,
      titleClass: titleClass,
      description: (
        <p className={descriptionClass}>
          {content.solutions.lambdaSAP.banners[3].content[0]}
        </p>
      ),
      rightImg: false,
      img: ImgPartner,
      imgPadding: { padding: "0 15%", maxWidth: "100%" },
    },
  ];

  const button = {
    title: content.solutions.lambdaSAP.finalSection.button,
    border: "none",
    padding: { padding: "2% 15%" },
    background: secondaryColor,
    font: "gothamBold font-button whiteColor",
    to: "/",
    replace: false,
  };

  return (
    <Wrapper>
      <SEO metadata={metadata.lambdaSAP} />
      {/*-------------------------------- MODALS --------------------------------*/}
      {/* DataSent Modal*/}
      <DataSent dataSentOpen={dataSentOpen} toggleDataSent={toggleDataSent} />
      {dataSentOpen && <Backdrop toggle={toggleDataSent} />}
      {/* DataSentError Modal*/}
      <DataSentError
        dataSentErrorOpen={dataSentErrorOpen}
        toggleDataSentError={toggleDataSentError}
      />
      {dataSentErrorOpen && <Backdrop toggle={toggleDataSentError} />}
      {/* Contact Modal*/}
      <Contact
        contactOpen={contactOpen}
        toggleContact={toggleContact}
        dataSentOpen={dataSentOpen}
        toggleDataSent={toggleDataSent}
        dataSentErrorOpen={dataSentErrorOpen}
        toggleDataErrorSent={toggleDataSentError}
        option={messageOptions(content).lambdaSAP[1].option}
        message={messageOptions(content).lambdaSAP[1].message}
      />
      {contactOpen && <Backdrop toggle={toggleContact} />}
      {/*-----------------------------------------------------------------------*/}

      <TitleImage
        type={initialBanner.titleImage.type}
        title={initialBanner.titleImage.title}
        subTitle={initialBanner.titleImage.subTitle}
        image={initialBanner.titleImage.image}
        button={initialBanner.titleImage.button}
      />
      <Info
        background={introduction.background}
        title={introduction.title}
        titleClass={introduction.titleClass}
        description={introduction.description}
        descriptionClass={introduction.descriptionClass}
        align={introduction.align}
      />
      <Info
        background={listTitle.background}
        title={listTitle.title}
        titleClass={listTitle.titleClass}
        description={listTitle.description}
        descriptionClass={listTitle.descriptionClass}
        align={listTitle.align}
      />
      {banners.map((elem, index) => {
        return (
          <div key={index}>
            <InfoImage
              key={elem.id}
              id={elem.id}
              background={elem.background}
              txtAlign={elem.txtAlign}
              title={elem.title}
              titleClass={elem.titleClass}
              description={elem.description}
              rightImg={elem.rightImg}
              img={elem.img}
              padding={elem.padding}
              imgPadding={elem.imgPadding}
            />
          </div>
        );
      })}
      <Solution
        title={button.title}
        font={button.font}
        background={button.background}
        border={button.border}
        innerPadding={"1.2% 5%"}
        to={button.to}
        replace={false}
        padding={button.padding}
        callBack={callbackFunction}
      />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  padding: 80px 0 5% 0;
`;

export default LambdaSAP;
